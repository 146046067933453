import {
  HomeOutlined,
  NotificationOutlined,
  SettingOutlined,
  LogoutOutlined,
  HeartOutlined,
  IdcardOutlined,
  // LinkOutlined,
} from '@ant-design/icons'

export const routes = [
  {
    path: '/my-dashboard',
    label: 'Home',
    icon: <HomeOutlined />,
    exact: true,
  },
  {
    path: '/my-campaigns',
    label: 'Campaigns',
    icon: <NotificationOutlined />,
  },
  {
    path: '/my-wishlist',
    label: 'Wishlist',
    icon: <HeartOutlined />,
  },
  //   {
  // path: '/affiliate',
  //     label:'Affiliate',
  //     icon: <LinkOutlined/>
  //   },
  {
    path: '/my-profile',
    label: 'Profile',
    icon: <IdcardOutlined />,
  },
  {
    path: '/my-settings',
    label: 'Settings',
    icon: <SettingOutlined />,
    sublinks: [
      { href: '#account', text: 'Account Details' },
      { href: '#shipping', text: 'Shipping Address' },
      { href: '#misc', text: 'Miscellaneous Info' },
      { href: '#social', text: 'Social Accounts' },
      { href: '#payouts', text: 'Payout Settings' },
    ],
  },
  {
    path: '/logout',
    label: 'Sign Out',
    icon: <LogoutOutlined />,
    className: 'logout',
  },
]
